
import React, { Component } from 'react';
import { Nav, NavDropdown, Navbar, Dropdown, Container } from 'react-bootstrap';
import '../assets/styles.scss';
import {
    Link
} from "react-router-dom";
import { req } from "../utils/request.js";
import AuthService from "../services/auth.service";
import logo from '../assets/logoBlank.png';
import { API_URL } from '../utils/config';
import SecuredComponent from '../components/SecuredComponent';
import { ROLE_COORDINADOR, ROLE_DIGITADOR, ROLE_SYSTEM_ADMIN, ROLE_USUARIO_CONSULTA } from '../utils/roles';
import { queryClient } from '../App.js';
import { ImportFormFromXlsx } from '../components/ImportFormFromXlsx.jsx';
class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organizacion: [],
            showImportForm: false
        };
        this.logout = this.logout.bind(this);
        this.onHideImportForm = this.onHideImportForm.bind(this);
        this.onOpenImportForm = this.onOpenImportForm.bind(this);
    }
    logout() {
        localStorage.clear();
        window.location.replace("/");
    }
    onHideImportForm() {
        this.setState({ showImportForm: false });
    }
    onOpenImportForm() {
        this.setState({ showImportForm: true });
    }
    componentDidMount() {
        let auth = JSON.parse(localStorage.getItem('user'));
        if (auth != undefined) {

            const organizacion = queryClient.getQueryData([`organizaciones/${auth?.user?.organizacion?.id}/show`])
            if (organizacion) {
                this.setState({ organizacion: organizacion });
            }

            // req.get(`${API_URL}organizaciones/${auth.user.organizacion.id}/show`).then(response => {
            //     // console.log({ response });
            //     this.setState({ organizacion: response.data.data ?? [] });
            // });
        }
    }
    render() {
        const auth = AuthService.getCurrentUser();
        return (
            <header>

                <Container>
                    <ImportFormFromXlsx show={this.state.showImportForm} onHide={this.onHideImportForm} />
                    <Navbar className="mainHeader">
                        <Navbar.Brand><Link to="/"><img alt="logo" style={{ maxHeight: '55px' }} src={this.state.organizacion.logo !== "" && this.state.organizacion.logo !== undefined ? this.state.organizacion.logo : logo} /></Link></Navbar.Brand>
                        <Navbar.Toggle />
                        {!this.props?.hideSubMenu && <Navbar.Collapse className="justify-content-end">

                            <SecuredComponent>
                                <>
                                    <Nav>
                                        <SecuredComponent roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                            <Nav.Item><Link className="nav-link" to="/registro"><span>Formulario de Registro</span></Link></Nav.Item>
                                        </SecuredComponent>
                                        <SecuredComponent roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR, ROLE_DIGITADOR]}>
                                            <Nav.Item><Link className="nav-link" to="/beneficiarios"><span>Listado de Beneficiarios</span></Link></Nav.Item>
                                        </SecuredComponent>
                                        <SecuredComponent roles={[ROLE_SYSTEM_ADMIN, ROLE_COORDINADOR, ROLE_DIGITADOR, ROLE_USUARIO_CONSULTA]}>
                                            <Nav.Item><Link className="nav-link" to="/reportes"><span>Reportes</span></Link></Nav.Item>
                                        </SecuredComponent>

                                        <SecuredComponent roles={[ROLE_SYSTEM_ADMIN]}>
                                            <NavDropdown className="encuesta" title="Mantenimiento de Encuesta" id="collasible-nav-dropdown">
                                                <Link
                                                    className="dropdown-item formulario"
                                                    to="/formularios"
                                                >
                                                    <span>Formulario</span>
                                                </Link>

                                                <Link className="dropdown-item tipo-formulario" to="/tipo-formulario"><span>Tipos de Formulario</span></Link>
                                                {/* <Link className="dropdown-item secciones" to="/secciones"><span>Secciones</span></Link>
                                                <Link className="dropdown-item campos" to="/preguntas"><span>Preguntas</span></Link> */}
                                                <Link className="dropdown-item tipo-pregunta" to="/tipo-pregunta"><span>Tipo de Pregunta</span></Link>
                                                <Link className="dropdown-item enlaces" to="/enlaces"><span>Enlaces</span></Link>
                                                <Link
                                                    className="dropdown-item importar-xlsforms"
                                                    to="#"
                                                    onClick={this.onOpenImportForm}
                                                >
                                                    <span>Importar XLSForms</span>
                                                </Link>

                                            </NavDropdown>
                                            <NavDropdown className="administracion" title="Administración" id="collasible-nav-dropdown">
                                                <Link className="dropdown-item organizaciones" to="/organizaciones"><span>Organizaciones</span></Link>
                                                <Link className="dropdown-item programas" to="/programas"><span>Programas</span></Link>
                                                <Link className="dropdown-item tipos-programas" to="/tipos-programas"><span>Tipos de Programas</span></Link>
                                                <Link className="dropdown-item tipos-actividades" to="/tipos-actividades"><span>Tipos de Actividades</span></Link>
                                                <Link className="dropdown-item actividades" to="/actividades"><span>Actividades</span></Link>
                                                <Link className="dropdown-item usuarios" to="/usuarios"><span>Usuarios</span></Link>
                                                <Link className="dropdown-item configuraciones" to="/configuraciones"><span>Configuraciones</span></Link>

                                                <NavDropdown.Divider />
                                                <Link className="dropdown-item provincias" to="/provincias"><span>Provincias</span></Link>
                                                <Link className="dropdown-item municipios" to="/municipios"><span>Municipios</span></Link>
                                                <Link className="dropdown-item sectores" to="/sectores"><span>Sectores</span></Link>
                                            </NavDropdown>
                                        </SecuredComponent>
                                        <SecuredComponent roles={[ROLE_COORDINADOR]}>
                                            <NavDropdown className="administracion" title="Administración" id="collasible-nav-dropdown">
                                                <Link className="dropdown-item actividades" to="/actividades"><span>Actividades</span></Link>
                                                <Link className="dropdown-item usuarios" to="/usuarios"><span>Usuarios</span></Link>
                                                <NavDropdown.Divider />
                                                <Link className="dropdown-item provincias" to="/provincias"><span>Provincias</span></Link>
                                                <Link className="dropdown-item municipios" to="/municipios"><span>Municipios</span></Link>
                                                <Link className="dropdown-item sectores" to="/sectores"><span>Sectores</span></Link>
                                            </NavDropdown>
                                        </SecuredComponent>
                                        <SecuredComponent roles={[ROLE_DIGITADOR]}>
                                            <NavDropdown className="administracion" title="Administración" id="collasible-nav-dropdown">
                                                <Link className="dropdown-item actividades" to="/actividades"><span>Actividades</span></Link>

                                            </NavDropdown>
                                        </SecuredComponent>
                                    </Nav>
                                    <Dropdown className="userProfile" alignright="true">
                                        <Dropdown.Toggle id="userProfile">
                                            {auth?.user?.username}
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu style={{ marginLeft: -100, width: 200 }}>
                                            <Dropdown.Item><Link to="/perfil/">Editar Perfil</Link></Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item><Link to="/generate-poll-offline/">Generar Encuestas Offline</Link></Dropdown.Item>
                                            <Dropdown.Divider />
                                            <Dropdown.Item onClick={() => { this.logout() }} >Salir</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </>
                            </SecuredComponent>

                        </Navbar.Collapse>}
                    </Navbar>



                    <h1 className="justify-content-end actividadesHeader">
                        {this.props.back ? (
                            <Link to={this.props.back}><span class="btnBack material-icons" aria-hidden="true">chevron_left</span></Link>
                        ) : this.props.back === '' ? <a style={{ cursor: 'pointer' }} onClick={e => {
                            this.props.history.goBack();
                        }}><span class="btnBack material-icons" aria-hidden="true">chevron_left</span></a>
                            : null}
                        {this.props.title}
                        <span>
                            <em className='numeroActividad'>{this.props.subtitle}</em>
                        </span>
                    </h1>

                </Container>
            </header>
        )
    }
}
export default Header;
