import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { object, string } from 'yup';
import { API_URL } from '../utils/config';
import { req } from '../utils/request';
import toast from 'react-hot-toast';

export function ImportFormFromXlsx({ show, onHide }) {
    const [tiposFormulario, setTiposFormulario] = useState([]);

    useEffect(() => {
        req.get(`${API_URL}tipos/formulario`, {
            params: { estado: "activos", paginacion: 999999 }
        }).then((response) => {
            setTiposFormulario(response?.data?.data ?? []);
        });
    }, []);

    const validationSchema = object().shape({
        nombre: string()
            .required('Nombre del formulario es requerido'),
        tipo_formulario: string()
            .required('Tipo de formulario es requerido'),
        archivo: string()
            .required('Archivo es requerido')
    });

    const initialValues = {
        nombre: '',
        tipo_formulario: '',
        archivo: null
    };

    return (
        <Modal size='lg' centered show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Importar Formulario</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    const formData = new FormData();
                    formData.append('nombre', values.nombre);
                    formData.append('tipo', values.tipo_formulario);
                    formData.append('plantilla', values.archivo);
                    formData.append('popup', 0);

                    req.post(`${API_URL}importar/formulario/import`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                        .then(() => {
                            toast.success('Formulario(s) importado(s) correctamente');
                            setSubmitting(false);
                            onHide();
                        }).catch((error) => {
                            toast.error(error?.response?.data?.message || error?.message);
                            setSubmitting(false);
                        })
                }}
            >
                {({ isSubmitting, handleChange, handleBlur, setFieldValue }) => (
                    <Form>
                        <Modal.Body>
                            <div className='form-group'>
                                <label className='form-label'>* Nombre del formulario</label>
                                <input
                                    type="text"
                                    name="nombre"
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                <ErrorMessage
                                    component='p'
                                    name='nombre'
                                    className='small text-danger'
                                />
                            </div>

                            <div className='form-group'>
                                <label className='form-label'>* Tipo de formulario</label>
                                <select
                                    name='tipo_formulario'
                                    className="form-control"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    <option value=''>Seleccione...</option>
                                    {tiposFormulario.map((tipo) => (
                                        <option key={tipo.id} value={tipo.id}>
                                            {tipo.nombre}
                                        </option>
                                    ))}
                                </select>
                                <ErrorMessage
                                    component='p'
                                    name='tipo_formulario'
                                    className='small text-danger'
                                />
                            </div>

                            <div className='form-group'>
                                <label className='form-label'>* Archivo (XLSX)</label>
                                <input
                                    type="file"
                                    accept=".xlsx"
                                    className="form-control"
                                    onChange={(event) => {
                                        setFieldValue('archivo', event.currentTarget.files[0]);
                                    }}
                                />
                                <ErrorMessage
                                    component='p'
                                    name='archivo'
                                    className='small text-danger'
                                />
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <button type='submit' className="btn btn-primary" disabled={isSubmitting}>
                                {isSubmitting ? 'Importando...' : 'Importar'}
                            </button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default ImportFormFromXlsx;
