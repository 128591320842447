import { useEffect, useState } from 'react';
import PollQuestion from './PollQuestion.jsx';
import { req } from '../utils/request.js';
import { API_URL } from '../utils/config.js';

export function PollSection({
  section,
  selectedAnswers,
  errors,
  handleChange,
  canEdit,
  form,
  publicPollType,
  encuesta
}) {
  const [itemsToShow, setItemsToShow] = useState([]);
  const TIPO_PREGUNTA_MATRIZ = 33;
  const TIPO_PREGUNTA_SELECCION_UNICA_IMAGEN = 34;
  // console.log({ section, itemsToShow });
  const areQuestionConditionsMet = ({ dependencias }) => {
    // Group dependencies by their logical operator (or/and)
    const groupedDeps = dependencias
      .filter(dep => dep.activo)
      .reduce((acc, dep) => {
        const [relational, logical] = dep.operador.split('|');
        if (!acc[logical]) acc[logical] = [];
        acc[logical].push({ ...dep, relationalOp: relational });
        return acc;
      }, {});

    // Helper function to evaluate a single condition
    const evaluateCondition = (dependencia) => {
      const itemId = `field_${dependencia.encuesta_item_id_dependencia}`;
      const answer = selectedAnswers[itemId];
      const value = answer?.value !== undefined ? answer.value : answer;

      // Different than (3)
      if (dependencia.relationalOp === '3') {
        return value !== dependencia.valor;
      }
      // Equal to (0)
      return value === dependencia.valor;
    };

    // Process 'and' conditions - all must be true
    const andConditions = groupedDeps['and'] || [];
    const andResult = andConditions.length === 0 ? true :
      andConditions.every(dep => evaluateCondition(dep));

    // Process 'or' conditions - at least one must be true
    const orConditions = groupedDeps['or'] || [];
    const orResult = orConditions.length === 0 ? true :
      orConditions.some(dep => evaluateCondition(dep));

    // Both 'and' and 'or' groups must be satisfied
    return andResult && orResult;
  };

  useEffect(() => {
    async function load() {
      const itemsToShow = await Promise.all(section.items
        .filter((item) => item.activo)
        .filter(areQuestionConditionsMet)
        // Esto es para remplazar la propiedad respuestas de cada pregunta con las respuestas del tipo de pregnta
        .map(async item => {
          let respuestas = item.respuestas;
          if (item.tipo_pregunta.general) {
            const response = await req.get(`${API_URL}${publicPollType ? 'enlaces/' : ''}respuestas/generales?paginacion=999999&tipo_pregunta=${item.tipo_pregunta.id}`);
            respuestas = response?.data?.data || [];
          }
          return { ...item, respuestas }
        })
      );
      setItemsToShow(itemsToShow);
    }
    load();
  }, [section, selectedAnswers]);

  // console.log({ section })
  return itemsToShow.length > 0 ? (
    <div className='row subBox mb-4'>
      <strong>{section.nombre}</strong>
      {itemsToShow.map((item) => (
        <div className={section.tipo == "general" && (item.tipo_pregunta.id != TIPO_PREGUNTA_MATRIZ && item.tipo_pregunta.id != TIPO_PREGUNTA_SELECCION_UNICA_IMAGEN) ? 'col-lg-4' : 'col-lg-12'} key={`question_container_${item.id}`}>
          <PollQuestion
            encuesta={encuesta}
            section={section}
            key={`question_${item.id}`}
            item={item}
            respuestas={item.respuestas}
            selectedAnswers={selectedAnswers}
            errors={errors}
            handleChange={handleChange}
            canEdit={canEdit}
            form={form}
            publicPollType={publicPollType}
          />
        </div>
      ))}
    </div>
  ) : null;
}

export default PollSection;
