import React, { Component, createRef } from "react";
import { Button, Col, Row, Form, Modal, Container, Nav } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import MaterialTable from "material-table";
import { Formik, Field, ErrorMessage } from "formik";
import { withRouter, Link } from "react-router-dom";
import * as Yup from "yup";
import { req } from "../utils/request.js";
import SortIcon from "@mui/icons-material/Sort";
import Alerts from "../common/Alerts.js";
import $ from "jquery";
import SpinnerPlaceholder from '../components/SpinnerPlaceholder';
import Audits from "../components/Audits.js";
import { checkStatus } from "../components/functions.js";

import HistoryIcon from "@mui/icons-material/History";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DoNotDisturbOnOutlinedIcon from "@mui/icons-material/DoNotDisturbOnOutlined";
import { API_URL } from "../utils/config.js";
import MultiSelect from "../components/MultiSelect.js";
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialog from "../components/ConfirmationDialog.jsx";
import toast from "react-hot-toast";
import { ROLE_SYSTEM_ADMIN } from "../utils/roles.js";

class PollForms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      popupAddForm: false,
      popupDuplication: false,
      forms: [],
      allForms: [],
      opcionesRequisito: [],
      editingData: [],
      isLoading: false,
      rowData: [],
      alertShow: false,
      alertMessage: "",
      alertType: "",
      auditType: "formularios",
      auditID: 0,
      showAudit: false,
      confirmModal: false,
      isAdmin: false
    };
    this.tableRef = createRef();
    this.editRow = this.editRow.bind(this);
    this.desactivateRow = this.desactivateRow.bind(this);
    this.activeRow = this.activeRow.bind(this);
    this.show_PopUpPullProgram = this.show_PopUpPullProgram.bind(this);
    this.hide_PopUpPullProgram = this.hide_PopUpPullProgram.bind(this);
    this.closeAudit = this.closeAudit.bind(this);
    this.show_PopupDuplication = this.show_PopupDuplication.bind(this);
    this.hide_PopupDuplication = this.hide_PopupDuplication.bind(this);
  }
  showAudit(data) {
    this.setState({
      auditID: data.id,
      showAudit: true,
    });
  }
  closeAudit() {
    this.setState({
      showAudit: false,
    });
  }

  componentDidMount() {
    const auth = JSON.parse(localStorage.getItem("user"));
    sessionStorage.clear();

    this.setState({
      isAdmin: auth.user?.role?.nombre === ROLE_SYSTEM_ADMIN,
    });

    if (this.props.location.state) {
      this.setState({
        alertShow: this.props.location.state.alertShow,
        alertMessage: this.props.location.state.alertMessage,
        alertType: this.props.location.state.alertType,

      });
    }
    req
      .get(`${API_URL}formularios`, { params: { paginacion: 999999 } })
      .then((response) => {
        this.setState({
          allForms: response.data.data ?? [],
        });
      });
    $(document).ready(function () {
      $(".nav-link").removeClass("active");
      $(".nav-item.encuesta .nav-link").addClass("active");
      $(".dropdown-item").removeClass("active");
      $(".dropdown-item.formulario").addClass("active");
    });
    setTimeout(() => {
      this.setState({ show: true });
    }, 200);
  }
  show_PopupDuplication(data) {
    this.setState({ popupDuplication: true, rowData: data });
  }
  hide_PopupDuplication() {
    this.setState({ popupDuplication: false });
  }
  show_PopUpPullProgram(data) {
    req
      .get(`${API_URL}tipos/formulario`, {
        params: { estado: "activos", paginacion: 999999 },
      })
      .then((response) => {
        this.setState({
          popupAddPollProgram: true,
          formtypes: response.data.data ?? [],
          editingData: data,
        });
      });
    req.get(`${API_URL}selectores/formularios`).then((response) => {
      this.setState({
        opcionesRequisito: [
          { label: "Ninguno", value: null },
          ...response.data.data
            .filter((elem) => elem.id !== data.id && elem.requisito === null)
            .map((elem) => ({
              label: elem.nombre,
              value: elem.id,
            })),
        ],
      });
    });
  }
  hide_PopUpPullProgram() {
    this.setState({ popupAddPollProgram: false });
  }
  editRow(data) {
    this.props.history.push(`/formulario/${data.id}`);
  }
  desactivateRow(data) {
    data.activo = false;
    req.post(`${API_URL}formularios/${data.id}/toggle`).then((response) => {
      this.setState({
        alertShow: true,
        alertType: "success",
        alertMessage: "El Formulario ha sido desactivado con éxito",
      });
    });
  }
  goToEditOrderIndexField(data) {
    this.props.history.push(
      `/formulario/edit-order-index-field/${data.id}?formulary_name=${data.nombre}`
    );
  }
  activeRow(data) {
    data.activo = true;
    req.post(`${API_URL}formularios/${data.id}/toggle`).then((response) => {
      this.setState({
        alertShow: true,
        alertType: "success",
        alertMessage: "El Formulario ha sido activado con éxito",
      });
    });
  }
  async duplicateForm(data) {
    try {
      this.setState({ isLoading: true });
  
      // Obtener datos del formulario original
      const response = await req.get(`${API_URL}formularios/${data.id}/show`);
      const formData = response.data.data;
  
      const idSecciones = [];
      const idPreguntas = [];
      const idRespuestas = [];
      const newPreguntas = [];
      const newSecciones = [];
  
      // Crear el formulario duplicado
      const newForm = {
        nombre: `${formData.nombre}_copia`,
        tipo: formData.tipo?.id,
        popup: formData.popup,
        popup_titulo: formData.popup_titulo,
        popup_cuerpo: formData.popup_cuerpo,
        logo: formData.logo_id,
        logo_url: formData.logo_url,
        logo_name: formData.logo_name,
        requisito: formData.requisito || undefined,
      };
  
      const formResponse = await req.post(`${API_URL}formularios/store`, newForm);
      const newFormId = formResponse.data.data.id;
  
      // Duplicar secciones
      await Promise.all(
        formData.secciones.map(async (seccion) => {
          if (seccion.activo) {
            const newSection = {
              formulario: newFormId,
              nombre: seccion.nombre,
              orden: seccion.orden,
              tipo: seccion.tipo,
            };
  
            const sectionResponse = await req.post(`${API_URL}formularios/secciones/store`, newSection);
            idSecciones.push({
              idSeccionBase: seccion.id,
              idSeccionCopia: sectionResponse.data.data.id,
            });
            newSecciones.push(sectionResponse.data.data);
          }
        })
      );
  
      // Obtener preguntas del formulario original
      const itemsResponse = await req.get(`${API_URL}formularios/items`, {
        params: {
          formulario: data.id,
          estado: 'activos',
          paginacion: 999999,
        },
      });
      const preguntas = itemsResponse.data.data;
  
      // Duplicar preguntas y respuestas
      await Promise.all(
        preguntas.map(async (pregunta) => {
          const fieldResponse = await req.get(`${API_URL}formularios/items/${pregunta.id}/show`);
  
          const seccionCopia = idSecciones.find((sec) => sec.idSeccionBase === pregunta.seccion.id);
          if (seccionCopia) {
            const newPregunta = {
              formulario_seccion: seccionCopia.idSeccionCopia,
              tipo_pregunta: pregunta.tipo_pregunta.id,
              orden: pregunta.orden,
              indice: pregunta.indice,
              pregunta: pregunta.pregunta,
              requerido: pregunta.requerido ? 1 : 0,
              codificacion: pregunta.codificacion,
            };
  
            const preguntaResponse = await req.post(`${API_URL}formularios/items/store`, newPregunta);
            const newPreguntaId = preguntaResponse.data.data.id;
            idPreguntas.push({ idPreguntaBase: pregunta.id, idPreguntaCopia: newPreguntaId });
            newPreguntas.push(preguntaResponse.data.data);
  
            // Duplicar respuestas de la pregunta
            const respuestasResponse = await req.get(`${API_URL}formularios/respuestas`, {
              params: {
                formulario_item: pregunta.id,
                estado: 'activos',
                paginacion: 999999,
              },
            });
  
            await Promise.all(
              respuestasResponse.data.data.map(async (respuesta) => {
                const newRespuesta = {
                  respuesta: respuesta.respuesta,
                  peso: respuesta.peso,
                  formulario_item: newPreguntaId,
                };
  
                const respuestaResponse = await req.post(`${API_URL}formularios/respuestas/store`, newRespuesta);
                idRespuestas.push({
                  idRespuestaBase: respuesta.id,
                  idRespuestaCopia: respuestaResponse.data.data.id,
                });
              })
            );
          }
        })
      );
  
      // Duplicar dependencias de preguntas
      await this.duplicateDependencies(newPreguntas, idPreguntas, idRespuestas);
  
      // Duplicar dependencias de secciones
      await this.duplicateSectionDependencies(newSecciones, idSecciones, idPreguntas, idRespuestas);
  
      this.props.history.push(`/formulario/${newFormId}`);
    } catch (error) {
      console.error("Error duplicando el formulario", error);
      alert(error.response?.data?.message || "Error inesperado");
      this.setState({ isLoading: false });
    }
  }
  
  async duplicateDependencies(newPreguntas, idPreguntas, idRespuestas) {
    await Promise.all(
      newPreguntas.map(async (p) => {
        const id = idPreguntas.find((sec) => sec.idPreguntaCopia === p.id);
        if (!id) return;
  
        try {
          const dependenciasResponse = await req.get(`${API_URL}formularios/items/dependencias/`, {
            params: {
              item: id.idPreguntaBase,
              paginacion: 999999,
            },
          });
  
          await Promise.all(
            dependenciasResponse.data.data.map(async (d) => {
              if (d.activo) {
                const formulario_item_id = idPreguntas.find((sec) => sec.idPreguntaBase === d.formulario_item_id);
                const formulario_item_dependencia = idPreguntas.find((sec) => sec.idPreguntaBase === d.formulario_item_id_dependencia);
                const respuesta = idRespuestas.find((sec) => sec.idRespuestaBase === d.respuesta.id);
  
                await req.post(`${API_URL}formularios/items/dependencias/store`, {
                  formulario_item: formulario_item_id?.idPreguntaCopia,
                  formulario_item_dependencia: formulario_item_dependencia?.idPreguntaCopia || null,
                  formulario_respuesta: respuesta?.idRespuestaCopia,
                  operador: d.operador,
                  valor: d.valor,
                });
              }
            })
          );
        } catch (error) {
          console.error("Error duplicando dependencias de preguntas", error);
        }
      })
    );
  }
  
  async duplicateSectionDependencies(newSecciones, idSecciones, idPreguntas, idRespuestas) {
    await Promise.all(
      newSecciones.map(async (seccion) => {
        const id = idSecciones.find((sec) => sec.idSeccionCopia === seccion.id);
        if (!id) return;
  
        try {
          const dependenciasResponse = await req.get(`${API_URL}formularios/secciones/dependencias`, {
            params: { seccion: id.idSeccionBase },
          });
  
          await Promise.all(
            dependenciasResponse.data.data.map(async (d) => {
              if (d.activo) {
                const formulario_seccion_id = idSecciones.find((sec) => sec.idSeccionBase === d.formulario_seccion_id);
                const formulario_item_dependencia = idPreguntas.find((sec) => sec.idPreguntaBase === d.formulario_item_id_dependencia);
                const respuesta = idRespuestas.find((sec) => sec.idRespuestaBase === d.respuesta?.id);
  
                await req.post(`${API_URL}formularios/secciones/dependencias/store`, {
                  formulario_seccion: formulario_seccion_id?.idSeccionCopia,
                  formulario_item_dependencia: formulario_item_dependencia?.idPreguntaCopia || null,
                  formulario_respuesta: respuesta?.idRespuestaCopia || null,
                  operador: d.operador,
                  valor: d.valor,
                });
              }
            })
          );
        } catch (error) {
          console.error("Error duplicando dependencias de secciones", error);
        }
      })
    );
  }
  




  render() {
    const {
      popupAddPollProgram,
      forms,
      opcionesRequisito,
      formtypes,
      editingData,
      alertMessage,
      alertShow,
      isLoading,
      alertType,
      popupDuplication
    } = this.state;
    const addFormValidationSchema = Yup.object().shape({
      nombre: Yup.string().required("El nombre es un campo requerido."),
      requisito: Yup.string().nullable(),
    });
    const formularioRequisitoSeleccionado = opcionesRequisito.find(
      (elem) => elem.value === editingData.requisito
    );
    return (
      <Container>
        {this.state.showAudit && (
          <Audits
            close={this.closeAudit}
            show={this.state.showAudit}
            tipo={this.state.auditType}
            id={this.state.auditID}
          />
        )}
        <ConfirmationDialog
          onConfirm={() => {
            req.delete(`${API_URL}formularios/${this.state.confirmModal.id}/delete`)
              .then(() => {
                this.tableRef.current.onQueryChange({});
                toast.success('Formulario eliminado exitosamente');
              }).catch((error) => {
                toast.error(error?.response?.data?.message || 'Error al eliminar el formulario');
              }).finally(() => {
                this.setState({ confirmModal: false });
              })
          }}
          onHide={() => {
            this.setState({ confirmModal: false });
          }}
          show={!!this.state.confirmModal}
        >
          <p className='text-center'>
            ¿Te gustaría remover el formulario?
          </p>

        </ConfirmationDialog>
        <div className="titleButtons">
          <Nav className="justify-content-end">
            <Alerts
              these={this}
              type={alertType}
              message={alertMessage}
              show={alertShow}
            />
            <Nav.Item>
              <Link className="nav-link" to="/formularios/crear">
                <span>Crear Formulario</span>
              </Link>
            </Nav.Item>
          </Nav>
        </div>
        <CSSTransition
          unmountOnExit
          in={this.state.show}
          timeout={200}
          classNames="transitionPage"
        >
          <div className="mainBox">
            <MaterialTable
              title=""
              tableRef={this.tableRef}
              columns={[
                {
                  title: "id",
                  defaultSort: "desc",
                  field: "id",
                },
                { title: "Nombre", field: "nombre" },
                {
                  title: "Tipo de Encuesta",
                  field: "tipo",
                  render: (rowData) => rowData.tipo.nombre,
                },
                {
                  title: "Formulario requisito",
                  field: "requisito",
                  render: (rowData) =>
                    returnFormularioRequerido(
                      this.state.allForms,
                      rowData.requisito
                    ),
                },
                {
                  title: "Estatus",
                  field: "activo",
                  render: (rowData) => checkStatus(rowData.activo),
                },
              ]}
              localization={{
                header: {
                  actions: "Acciones",
                },
                toolbar: {
                  searchTooltip: "Buscar",
                  searchPlaceholder: "Buscar",
                },
                pagination: {
                  previousTooltip: "Página anterior",
                  nextTooltip: "Página siguiente",
                  firstTooltip: "Primera página",
                  lastTooltip: "Última página",
                },
                body: {
                  emptyDataSourceMessage: "No hay registros que mostrar",
                },
              }}
              options={{
                search: true,
                selection: false,
                searchFieldAlignment: "right",
                paging: true,
                actionsColumnIndex: -1,
                pageSize: 20,
                emptyRowsWhenPaging: false,
                draggable: false,
              }}
              data={(query) => {
                this.setState({ query });
                // TODO implement filters
                return new Promise((resolve) => {
                  const params = {
                    page: query.page + 1,
                    paginacion: this.tableRef.current.dataManager.pageSize,
                  };
                  if (query.search) {
                    params.busqueda = query.search;
                  }
                  req
                    .get(`${API_URL}formularios`, { params })
                    .then((response) => {
                      resolve({
                        data: response.data.data,
                        page: response.data.meta.current_page - 1,
                        totalCount: response.data.meta.total,
                      });
                    });
                });
              }}
              actions={[
                {
                  icon: ModeEditOutlinedIcon,
                  tooltip: "Editar",
                  onClick: (event, rowData) => this.editRow(rowData),
                },
                (rowData) => ({
                  icon: SortIcon,
                  tooltip: "Editar Orden/Índice",
                  onClick: (event, rowData) =>
                    this.goToEditOrderIndexField(rowData),
                }),
                (rowData) => ({
                  icon: DoNotDisturbOnOutlinedIcon,
                  tooltip: "Desactivar",
                  onClick: (event, rowData) => this.desactivateRow(rowData),
                  disabled: rowData.activo === false,
                }),
                (rowData) => ({
                  icon: CheckCircleOutlinedIcon,
                  tooltip: "Activar",
                  onClick: (event, rowData) => this.activeRow(rowData),
                  disabled: rowData.activo === true,
                }),
                (rowData) => ({
                  icon: ContentCopyIcon,
                  tooltip: "Duplicar",
                  onClick: (event, rowData) => this.show_PopupDuplication(rowData),
                }),
                (rowData) => ({
                  icon: HistoryIcon,
                  tooltip: "Auditoria",
                  onClick: (event, rowData) => this.showAudit(rowData),
                }),
                (rowData) => ({
                  icon: DeleteIcon,
                  tooltip: "Eliminar",
                  onClick: (event, rowData) =>
                    this.setState({
                      confirmModal: rowData
                    }),
                  disabled: !this.state.isAdmin
                }),
              ]}
            />
          </div>
        </CSSTransition>
        <Modal
          centered
          show={popupAddPollProgram}
          onHide={this.hide_PopUpPullProgram}
        >
          <Modal.Header closeButton>
            <Modal.Title>Guardar Formulario</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{
                nombre: editingData.nombre || "",
                tipo: editingData.tipo?.id || "",
                requisito: editingData.requisito || "",
              }}
              validationSchema={addFormValidationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setSubmitting(true);

                this.hide_PopUpPullProgram();
                values.activo = 1;
                if (!values.requisito) {
                  delete values.requisito;
                }

                if (editingData.id !== undefined) {
                  delete values.id;
                  req
                    .post(
                      `${API_URL}formularios/${editingData.id}/update`,
                      values
                    )
                    .then(() => {
                      this.setState({
                        alertShow: true,
                        alertType: "success",
                        alertMessage: "El formulario fue actualizado con éxito",
                      });
                      this.tableRef.current.onQueryChange({});
                    });
                } else {
                  req
                    .post(`${API_URL}formularios/store`, values)
                    .then((response) => {
                      this.setState({
                        forms: [...forms, response.data.data],
                        alertShow: true,
                        alertType: "success",
                        alertMessage: "El formulario fue creado con éxito",
                      });
                      this.tableRef.current.onQueryChange({});
                    });
                }
                this.hide_PopUpPullProgram();
                resetForm();
                setSubmitting(false);

              }}
            >
              {({ handleSubmit, setFieldValue, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col lg="12">
                      <div className="form-group">
                        <label>Nombre</label>
                        <Field type="text" name="nombre" />
                        <ErrorMessage
                          className="errorField"
                          name="nombre"
                          component="p"
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="form-group">
                        <label>Tipo de Encuesta</label>
                        <Field name="tipo" as="select">
                          <option value="null"> Selecciona </option>
                          {formtypes.map((elem) => (
                            <option key={elem.id} value={elem.id}>
                              {elem.nombre}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          className="errorField"
                          name="tipo"
                          component="p"
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="form-group">
                        <label>Formulario requisito</label>
                        <MultiSelect
                          name="requisito"
                          defaultValue={formularioRequisitoSeleccionado}
                          onChange={(e) => {
                            setFieldValue("requisito", e);
                          }}
                          options={opcionesRequisito}
                        />
                        <ErrorMessage
                          component="p"
                          name="requisito"
                          className="small text-danger"
                        />
                      </div>
                    </Col>
                    <Col lg="12">
                      <div className="contButtons">
                        <Button
                          type="submit"
                          disabled={isSubmitting}
                          variant="primary "
                        >
                          {isSubmitting ? "Guardando..." : "Guardar"}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        <Modal
          centered
          show={popupDuplication}
          onHide={this.hide_PopupDuplication}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p style={{ textAlign: 'center' }}>
              ¿Estás seguro que desea duplicar este formulario?
            </p>
          </Modal.Body>
          <Modal.Footer style={isLoading ? { display: "block", margin: "0 auto" } : {}}>
            <SpinnerPlaceholder isLoading={isLoading}>
              <Button onClick={this.hide_PopupDuplication}>No</Button>
              <Button
                onClick={() => this.duplicateForm(this.state.rowData)}
              >
                Si
              </Button>
            </SpinnerPlaceholder>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}
function returnFormularioRequerido(data, value) {
  for (var i = 0, len = data.length; i < len; i++) {
    if (data[i].id === value) {
      return data[i].nombre;
    }
  }
}
export default withRouter(PollForms);
